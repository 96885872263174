const tertiary = {
  main: '#FFF9A6',
  dark: '#CCC784',
}

const red = {
  dark: '#8b0000',
  darker: '#580000',
  evenDarker: '#250000',
}

export { tertiary, red }
